import React from "react";
import Enquiry from "../../Components/EnquiryForm";
import styles from "./Contact.module.scss";
import Header from "../../Components/Header";
import Navigation from "../../Components/Navigation/Navigation";

const Contact = () => {
  return (
    <div className='page'>
      <Navigation />
      <Header title={"Contact"} />
      <section className={styles.wrapper}>
        <Enquiry />
      </section>
    </div>
  );
};

export default Contact;
