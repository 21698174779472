import React from "react";
import styles from "./Menu.module.scss";
import Button from "../Buttons";
import buttonStyles from "../Buttons/Button.module.scss";

const Menu = ({
  name,
  items = [],
  type = "horizontal",
  className,
  listClassName,
  itemClassName,
  onCloseMenu,
}) => {
  return (
    <nav
      className={`${styles.nav} ${styles[type]} ${
        styles[className] ? styles[className] : ""
      }`}
      aria-label={name ? name : ""}>
      <ul className={`${styles.list} ${listClassName ? listClassName : ""}`}>
        {Array.isArray(items) &&
          items.map((item, index) => (
            <li className={styles.item} key={`menu__item_${index}`}>
              <Button
                className={
                  itemClassName ? itemClassName : buttonStyles.button_link
                }
                to={item.path || item.to} // Use either path or to, whichever is available
                onClick={onCloseMenu}>
                {item.label}
              </Button>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Menu;
