import React from "react";
import styles from "./EnquiryContact.module.scss";
import Message from "../Message/Message";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const EnquiryContact = () => {
  return (
    <>
      <ScrollToTop />
      <Message type={"success"}>
        <h2 className={styles.title}>Success</h2>
        <p className={styles.body}>Your message has been sent</p>
      </Message>
    </>
  );
};

export default EnquiryContact;
