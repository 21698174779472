import React, { useEffect, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// import { Helmet } from "react-helmet-async";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Menu, { FooterMenu } from "./Components/Menu";
import Copyright from "./Components/Copyright/Copyright";
import CookieConsent from "./Components/CookieConsent";
import Contact from "./Pages/Contact/Contact";
import Footer from "./Components/Footer";
import MailtoUI from "/node_modules/mailtoui/dist/mailtoui-min.js"; // adjust based on the actual path
import Aos from "aos";

import { PropagateLoader } from 'react-spinners';

import "./style.scss";
import "aos/dist/aos.css";
// import Navigation from "./Components/Navigation/Navigation";

const Home = React.lazy(() => import("./Pages/Home"));
const Blogs = React.lazy(() => import("./Pages/Blogs"));
const Blog = React.lazy(() => import("./Pages/Blog"));
// const HoldingPage = React.lazy(() => import("./Pages/Holding"));

function App() {
  useEffect(() => {
    MailtoUI.run();
    setTimeout(() => {
      Aos.init({
        offset: 200,
      });
    }, 500); // initializing AOS after 500ms
  }, []);

  const handleConsent = () => {
    // Initialize Google Analytics or other scripts here
    console.log("User consented!");
  };

  const handleDeny = () => {
    console.log("User denied consent!");
    // Optionally handle any cleanup or additional actions
  };

  const location = useLocation();
  const isBlogPage = location.pathname.startsWith('/blog/');

  return (
    <div className={isBlogPage ? 'blog-page' : ''}>
      {/* <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="
            default-src 'self' https://fonts.gstatic.com https://api.channel-sec.com;
            script-src 'self' https://www.google.com/recaptcha/api.js https://channel-sec.com/static/js/;
            style-src 'self' https://cdnjs.cloudflare.com https://fonts.googleapis.com 'unsafe-inline';
            font-src 'self' https://cdnjs.cloudflare.com https://fonts.gstatic.com;
            media-src 'self' https://player.vimeo.com;
            frame-src 'self' https://www.google.com/maps/embed https://player.vimeo.com/video/;
        "
        />
      </Helmet> */}

      <ScrollToTop />
      {/* <Navigation/> */}
      <Suspense fallback={<div className="page"><PropagateLoader color="#36d7b7" size={20} /></div>}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/blog" element={<Blogs />} />
          <Route exact path="/blog/:slug" element={<Blog />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </Suspense>
      <Footer>
        <Menu className={"footer__menu"} items={FooterMenu} />

        <Copyright />
      </Footer>
      <CookieConsent onConsent={handleConsent} onDeny={handleDeny} />
    </div>
  );
}

export default App;
