import React from "react";
import styles from "./Header.module.scss";
import { LIVE_SITE_API, SITE_URL } from "../../Constants/constants";

const Header = ({ title, backgroundImage, offset = 0 }) => {
  const background = backgroundImage
    ? backgroundImage
    : `${LIVE_SITE_API}/api/uploads/monk7mad_cyber_criminal_isolated_on_dark_hooded_computer_concep_3d3e27a5_7db9_43fd_8c54_95cc297e18c2_1a5596bcfd.png`;

  return (
    <div className={styles.container} style={{ marginTop: `${offset}px` }}>
      {background && (
        <div
          className={styles.background}
          style={{ backgroundImage: `url(${background})` }}></div>
      )}
      {title && <h1 className={styles.title}>{title}</h1>}
      <div className={styles.overlay}></div>
    </div>
  );
};

export default Header;
