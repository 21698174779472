export const FooterMenu = [
  {
    label: "Privacy Policy",
    path: "/privacy-policy",
  },
  {
    label: "Cookie Policy",
    path: "/cookie-policy",
  },
  {
    label: "Sitemap",
    path: "sitemap.xml",
  }
];
