import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { basicSchema } from "./schemas";
import axios from "axios";
import Modal from "../Modal/Modal";
import styles from "./Form.module.scss";
import buttonStyles from "../Buttons/Button.module.scss";
import { SITE_NAME, SITE_URL, LIVE_SITE_API } from "../../Constants/constants";
import EnquiryContact from "./EnquiryContact";

function Enquiry() {
  const [submitMessage, setSubmitMessage] = useState({ text: "", type: "" });

  const onSubmit = async (values, actions) => {
    try {
      const formData = {
        name: `${values.firstName} ${values.lastName}`,
        email: values.email,
        message: values.userMessage,
      };
      const formSubmission = {
        formName: "Contact",
        formData,
      };

      const response = await axios.post(
        `${LIVE_SITE_API}/api/ezforms/submit`,
        formSubmission, // Pass the structured data
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Assuming success if we reach this point since an exception would have been thrown otherwise
      setSubmitMessage({
        text: "Your message has been successfully sent.",
        type: "success",
      });

      actions.resetForm();
    } catch (error) {
      console.error("Error submitting the form:", error);
      // Handle specific error response if available, else generic error message
      const errorMessage =
        error.response?.data?.error?.message ||
        "An error occurred while submitting the form.";
      setSubmitMessage({
        text: errorMessage,
        type: "error",
      });
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      userMessage: "",
      terms: false,
    },
    validationSchema: basicSchema,
    onSubmit,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTermsChange = (event) => {
    setFieldValue("terms", event.target.checked);
  };

  return (
    <>
      <div className={styles.wrapper}>
        {submitMessage.type === "success" && <EnquiryContact />}
        <form
          name='contact'
          onSubmit={handleSubmit}
          className={`${styles.form}`}>
          <div className={styles.form__group}>
            <div className={styles.form__item}>
              <label htmlFor='firstName'>First name</label>
              <input
                id='firstName'
                name='firstName'
                type='text'
                placeholder='Enter your first name'
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.firstName && touched.firstName
                    ? styles.input_error
                    : ""
                }
                required
              />
              {errors.firstName && touched.firstName && (
                <em className={styles.message_error}>{errors.firstName}</em>
              )}
            </div>
            <div className={styles.form__item}>
              <label htmlFor='lastName'>Last name</label>
              <input
                id='lastName'
                name='lastName'
                type='text'
                placeholder='Enter your last name'
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.lastName && touched.lastName ? styles.input_error : ""
                }
                required
              />
              {errors.lastName && touched.lastName && (
                <em className={styles.message_error}>{errors.lastName}</em>
              )}
            </div>
          </div>

          <div className={styles.form__item}>
            <label htmlFor='email'>Email Address</label>
            <input
              id='email'
              type='email'
              placeholder='Enter your email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.email && touched.email ? styles.input_error : ""
              }
              required
            />
            {errors.email && touched.email && (
              <em className={styles.message_error}>{errors.email}</em>
            )}
          </div>

          <div className={styles.form__item}>
            <label htmlFor='userMessage'>Message</label>
            <textarea
              id='userMessage'
              name='userMessage'
              placeholder='Enter your message...'
              cols={40}
              rows={10}
              value={values.userMessage}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.userMessage && touched.userMessage
                  ? styles.input_error
                  : ""
              }
              required
            />
            {errors.userMessage && touched.userMessage && (
              <em className={styles.message_error}>{errors.userMessage}</em>
            )}
          </div>

          <div className={styles.form__item}>
            <div className={`${styles.form__checkbox}`}>
              <input
                id='terms'
                type='checkbox'
                checked={values.terms}
                onChange={handleTermsChange}
                onBlur={handleBlur}
                className={`input_checkbox ${
                  errors.terms && touched.terms ? styles.input_error : ""
                }`}
                required
              />

              <div>
                <label htmlFor='terms'>Terms and conditions</label>
                <small className={styles.terms__description}>
                  By submitting this form, you confirm that you have read and
                  agree to the{" "}
                  <button
                    onClick={handleOpenModal}
                    aria-label={`Open terms and conditions}`}
                    className={`${buttonStyles.button_link} ${buttonStyles.btn_link_primary}`}>
                    Terms and Conditions
                  </button>
                </small>
              </div>
            </div>

            {errors.terms && touched.terms && (
              <em className={styles.message_error}>{errors.terms}</em>
            )}
          </div>

          {/* <input type='hidden' name='csrfToken' value={csrfToken} /> */}

          <button
            disabled={isSubmitting}
            className={`${buttonStyles.button} ${buttonStyles.btn_primary} ${
              isSubmitting ? "input_submitting" : ""
            }`}
            type='submit'>
            Send enquiry
          </button>
        </form>

        <Modal
          title={"Terms & Conditions"}
          content={`By providing your details, you are agreeing to allow me to contact you for the purposes of following up.`}
          isOpen={isModalOpen}
          onClose={handleCloseModal} // Pass a function to close the modal from the Modal component
        />
      </div>
    </>
  );
}

export default Enquiry;
