/* Site Settings */
const LIVE_SITE_URL = "https://portal.daherhussein.com/public/";
export const LIVE_SITE_API = "https://portal.daherhussein.com";
const DEV_SITE_URL = "http://localhost:1337";

/*
  Enable Development mode
  This will make it so all URLs in the site point to the local Strapi.
*/
const DEV_MODE = false;

export const SITE_URL = () => (DEV_MODE ? DEV_SITE_URL : LIVE_SITE_URL);


/* Site Information */
export const SITE_NAME = "Hussein Daher";
export const SITE_SLOGAN = "Lorem Ipsum dolor sit amet segor"


/* Date */
const today = new Date();
export const CURRENT_YEAR = today.getFullYear();