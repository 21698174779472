import * as yup from "yup";

const stringOnlyRules = /^([a-zA-Z\s-']*)$/;

export const basicSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email address is required"),
  firstName: yup
    .string()
    .min(2)
    .matches(stringOnlyRules, { message: "Please enter a valid name" })
    .required("Required"),
  lastName: yup
    .string()
    .min(2)
    .matches(stringOnlyRules, { message: "Please enter a valid name" })
    .required("Required"),
  userMessage: yup.string().required("Required"),
  terms: yup
    .bool()
    .oneOf([true], "Accept terms and conditions")
    .required("Required"),
});
