import React from "react";
import styles from "./Message.module.scss";

const Message = ({ type, children }) => {
  return (
    <div
      className={`${styles.message} ${styles["message_" + type]}`}
      role='status'>
      {children}
    </div>
  );
};

export default Message;
