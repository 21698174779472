import React, { useEffect } from "react";
import "./Modal.scss";

function Modal(props) {
  // Use the props to determine if the modal is open
  const isOpen = props.isOpen;

  // useEffect hook to handle the body's class update
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scrolling");
    } else {
      document.body.classList.remove("no-scrolling");
    }


    // Cleanup function to ensure the class is removed when the component is unmounted
    return () => {
      document.body.classList.remove("no-scrolling");
    };
  }, [isOpen]);

  // Define a function to handle clicks on the background outside the modal
  function handleBackgroundClick(e) {
    // If the target of the click is the same as the container div, close the modal
    if (e.target === e.currentTarget && props.onClose) {
      props.onClose();
    }
  }

  return (
    <div>
      {/* Render the modal based on the isOpen prop */}
      {isOpen && (
        <div className='modal' onClick={handleBackgroundClick}>
          <div className='modal__content'>
            {/* Render a close button for the modal */}
            <span className='close' onClick={props.onClose}>
              &times;
            </span>
            {/* Render the title of the modal */}
            <h2 className='modal__title'>{props.title}</h2>
            <div className='modal__body'>
              {/* If there is a videoSrc prop, render the iframe */}

              {props.videoSrc && (
                <div
                  className='modal__video'
                  style={{ paddingTop: "56.25%", position: "relative" }}>
                  <iframe
                    title={props.title}
                    src={props.videoSrc}
                    frameBorder='0'
                    allow='autoplay; fullscreen'
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                    }}></iframe>
                </div>
              )}

              {/* If there is an image prop, render an image element */}
              {props.image && (
                <div className='modal__image'>
                  <img src={props.image} alt={props.title} />
                </div>
              )}

              {/* If there is a content prop and it is an array, render each item in the array as a paragraph */}
              {Array.isArray(props.content) ? (
                <>
                  {props.content.map((paragraph) => (
                    <p key={paragraph} className='modal__paragraph'>
                      {paragraph}
                    </p>
                  ))}
                </>
              ) : (
                // Otherwise, render the content prop as a single paragraph
                <p className='modal__paragraph'>{props.content}</p>
              )}
            </div>
            {/* If there is an actionlink prop, render a link button */}
            {props.actionlink && (
              <div className='modal__actions'>
                <a href={props.actionlink} className='button'>
                  {props.actiontext}
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
