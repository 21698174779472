export const PrimaryMenu = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "About",
    path: "/#about",
  },
  {
    label: "Work",
    path: "/#work",
  },
  {
    label: "Blog",
    path: "/blog",
  },
  {
    label: "Contact",
    path: "/contact",
  },
];

export const SecondaryMenu = [
  {
    label: "X (Formerly Twitter)",
    path: "#",
  },
  {
    label: "Instagram",
    path: "#"
  }
];
