// CookieConsent.js

import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import styles from "./CookieConsent.module.scss";
import { buttonStyles } from "../Buttons";

const CookieConsent = ({ onConsent, onDeny }) => {
  const userConsentCookie = Cookies.get("userConsent");
  const defaultConsent =
    userConsentCookie === "true"
      ? true
      : userConsentCookie === "false"
      ? false
      : null;
  const [temporaryCookie, setTemporaryCookie] = useState(false);

  const [hasConsent, setHasConsent] = useState(defaultConsent);

  useEffect(() => {
    if (hasConsent === true) {
      Cookies.set("userConsent", "true", { expires: 365, path: "/" });
      if (onConsent) onConsent();
    } else if (hasConsent === false) {
      setTemporaryCookie(true);
      if (onDeny) onDeny();
    }
  }, [hasConsent, onConsent, onDeny]);

  if (hasConsent !== null) return null;

  return (
    <div
      className={`${styles.cookie} ${
        temporaryCookie ? styles.cookie_hidden : ""
      }`}>
      <div className={styles.cookie__content}>
        <h2 className={styles.cookie__title}>
          Do you consent to our use of cookies?
        </h2>
        <small className={styles.cookie__summary}>
          We need your permission to store and access cookies, unique
          identifiers, and information on your browsing behaviour on this
          device. You can read our{" "}
          <a
            href='https://www.bpl-business.com/privacy-policy'
            target='_blank'
            className={`${buttonStyles.btn_link} ${styles.cookie__link}`}
            rel='noreferrer'>
            cookie policy here
          </a>
          .
        </small>
      </div>
      <div className={styles.cookie__actions}>
        <button
          className={`${styles.button} ${buttonStyles.button} ${buttonStyles.button_small} ${buttonStyles.btn_secondary}`}
          onClick={() => setHasConsent(true)}
          aria-label='Allow consent to the use of cookies'>
          Yes, I consent
        </button>
        <button
          className={`${styles.button} ${buttonStyles.button} ${buttonStyles.button_small} ${buttonStyles.btn_accent}`}
          onClick={() => setHasConsent(false)}
          aria-label='Deny consent to the use of cookies'>
          No, I don't consent
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
